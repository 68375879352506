import { CSSProperties } from "react";
import { BASE_ENDPOINT } from "../api/config";
import { urlJoin } from "../api/utils";

const COLOR_CONFIGS = {
    minPsf: 10,
    maxPsf: 110,
    minPrice: 0,
    maxPrice: 5000
}

const COLOUR_RANGE = [
    { pct: 0.0, color: { r: 0, g: 255, b: 255 } },   // Cyan for lowest values
    { pct: 0.2, color: { r: 0, g: 255, b: 0 } },     // Green
    { pct: 0.4, color: { r: 255, g: 255, b: 0 } },   // Yellow
    { pct: 0.6, color: { r: 255, g: 165, b: 0 } },   // Orange
    { pct: 0.8, color: { r: 255, g: 0, b: 0 } },     // Red
    { pct: 1.0, color: { r: 128, g: 0, b: 128 } }    // Purple for highest values
];

const DEFAULT = {
    fillStyle: 'rgba(0, 0, 0, 0.0)',
    strokeStyle: 'rgba(0, 0, 0, 0.0)',
    lineWidth: 1
} as CSSProperties;

const ARTICLE4 = {
    fillStyle: 'rgba(200, 25, 0, 0.4)',
    strokeStyle: 'rgba(200, 25, 0, 0.8)',
    lineWidth: 1,
} as CSSProperties;

const NHLE = {
    fillStyle: 'rgba(115, 255, 0, 1)',
    strokeStyle: 'rgba(2, 0, 255, 1)',
    lineWidth: 1,
    radius: 5
} as CSSProperties;

const getPsfColor = (value: number) => {
    // Normalize value between COLOR_CONFIGS.minPsf and COLOR_CONFIGS.maxPsf
    value = (value - COLOR_CONFIGS.minPsf) / (COLOR_CONFIGS.maxPsf - COLOR_CONFIGS.minPsf);
    value = Math.max(0, Math.min(value, 1)); // Ensure value is between 0 and 1

    // Find where 'value' falls proportionally within the color ranges
    let color1 = COLOUR_RANGE[0].color;
    let color2 = COLOUR_RANGE[COLOUR_RANGE.length - 1].color;
    let pct1 = COLOUR_RANGE[0].pct;
    let pct2 = COLOUR_RANGE[COLOUR_RANGE.length - 1].pct;

    for (let i = 0; i < COLOUR_RANGE.length; i++) {
        if (value >= COLOUR_RANGE[i].pct) {
            pct1 = COLOUR_RANGE[i].pct;
            color1 = COLOUR_RANGE[i].color;
        }
        if (value < COLOUR_RANGE[i].pct) {
            pct2 = COLOUR_RANGE[i].pct;
            color2 = COLOUR_RANGE[i].color;
            break;
        }
    }

    let pct;
    if (pct2 !== pct1) {
        pct = (value - pct1) / (pct2 - pct1);
    } else {
        // If pct2 and pct1 are equal, use color1 directly
        return `rgb(${color1.r}, ${color1.g}, ${color1.b}, 0.4)`;
    }

    pct = (value - pct1) / (pct2 - pct1);
    const r = Math.round(color1.r + pct * (color2.r - color1.r));
    const g = Math.round(color1.g + pct * (color2.g - color1.g));
    const b = Math.round(color1.b + pct * (color2.b - color1.b));

    return `rgb(${r}, ${g}, ${b}, 0.4)`;
}

const getMSOAStyle = (feature: any) => {
    // console.log(feature);
    let psfDeciles = feature.properties.listed_psf_deciles;
    if (typeof psfDeciles === 'string') {
        psfDeciles = JSON.parse(psfDeciles);
    }

    if (!psfDeciles || !Array.isArray(psfDeciles)) {
        return DEFAULT;
    }

    const psf = psfDeciles[6];
    if (psf !== undefined && psf !== null) {
        return {
            fillStyle: getPsfColor(parseFloat(psf)),
            strokeStyle: 'rgba(0,0,0,0.4)',
            lineWidth: 1,
            selected: {
                fillStyle: 'rgba(255,140,0,0.3)',
                strokeStyle: 'rgba(255,140,0,1)',
                lineWidth: 2
            }
        }
    }
}

export const ClickOptions = {
    multipleSelection: true, // Multiple feature selection
    setSelected: true, // set feature as selected
    toggleSelection: true // toggle selection on click
};

export const MvtSourceOptions = {
    url: urlJoin(BASE_ENDPOINT, 'tiles/{z}/{x}/{y}'),
    sourceMaxZoom: 14,
    cache: true,
    debug: false,
    getIDForLayerFeature: (feature: any) => {
        return feature.properties.id;
    },
    clickableLayers: ['msoa_4326', 'nhle_4326'],
    style: (feature: any) => {
        switch (feature.properties.layer) {
            case 'msoa_4326':
                // return DEFAULT;
                return getMSOAStyle(feature);
            case 'a4_4326':
                return ARTICLE4;
            case 'nhle_4326':
                return NHLE;
            default:
                return DEFAULT;
        }
    },
}