import { FC, useState, useCallback, useMemo } from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Box from '@mui/joy/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Table, { TableProps } from '@mui/joy/Table';

function round(x: number, decimals: number = 2) {
  return Math.round(x * 10 ** decimals) / 10 ** decimals;
}

const MetricTable: FC<{ title: string; rows: any }> = ({ title, rows }) => (
  <Box sx={{ mt: 2 }}>
    <Typography level="h3" fontSize="lg" mb="8px">
      {title}
    </Typography>
    <Table borderAxis="xBetween" size="sm">
      <thead>
        <tr>
          <th>metric</th>
          <th>rent</th>
          <th>sale</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row: any) => {
          const { metricName, rent, sale } = row;
          return (
            <tr key={metricName}>
              <th scope="row">{metricName}</th>
              <td>{rent ?? 'NA'}</td>
              <td>{sale ?? 'NA'}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  </Box>
);

const listedMetricFields = {
  'price range': 'listed_price_deciles',
  price: 'listed_price_deciles',
  area: 'listed_area_deciles',
  'psf range': 'listed_psf_deciles',
  psf: 'listed_psf_deciles',
  'data points': 'listed_data_points',
};

const delistedMetricFields = {
  'price range': 'delisted_price_deciles',
  price: 'delisted_price_deciles',
  area: 'delisted_area_deciles',
  'psf range': 'delisted_psf_deciles',
  psf: 'delisted_psf_deciles',
  'data points': 'delisted_data_points',
};

const RentMetricCard: FC<{ msoaCode: string; metrics: any; percentile: number }> = ({
  msoaCode,
  metrics,
  percentile,
}) => {
  const { name, rental, sale } = metrics;
  const [expanded, setExpanded] = useState(false);

  const psfIndex = useMemo(() => Math.floor(percentile / 10), [percentile]);

  const createData = useCallback(
    (metricName: string, metricField: string) => {
      const getValue = (metric: any) => {
        const value = metric[metricField];
        if (value === undefined || value === null) {
          return 'No data';
        }

        if (metricName.includes('range')) {
          return `${round(value[0]).toLocaleString()} - ${round(value.at(-1)).toLocaleString()}`;
        } else {
          if (typeof value === 'number') {
            return Math.floor(value).toLocaleString();
          } else if (Array.isArray(value)) {
            return round(value[psfIndex]).toLocaleString();
          } else {
            return 'NA';
          }
        }
      };
      return { metricName, rent: getValue(rental), sale: getValue(sale) };
    },
    [psfIndex]
  );

  const handleExpand = () => setExpanded(!expanded);

  const listData = Object.entries(listedMetricFields).map(([metricName, metricField]) =>
    createData(metricName, metricField)
  );
  const delistedData = Object.entries(delistedMetricFields).map(([metricName, metricField]) =>
    createData(metricName, metricField)
  );

  return (
    <Card variant="outlined" sx={{ width: '95%', mb: 2, mt: 2 }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
            {`${name} (${msoaCode})`}
          </Typography>
          <IconButton aria-label="expand" size="sm" onClick={handleExpand}>
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
        <Typography level="body-sm">
          {`[rental] listed psf: ${
            rental['listed_psf_deciles'] ? round(rental['listed_psf_deciles'][psfIndex]).toLocaleString() : 'NA'
          } | delisted psf: ${
            rental['delisted_psf_deciles'] ? round(rental['delisted_psf_deciles'][psfIndex]).toLocaleString() : 'NA'
          }`}
        </Typography>
        <Typography level="body-sm">
          {`[sale] listed psf: ${
            sale['listed_psf_deciles'] ? round(sale['listed_psf_deciles'][psfIndex]).toLocaleString() : 'NA'
          } | delisted psf: ${
            sale['delisted_psf_deciles'] ? round(sale['delisted_psf_deciles'][psfIndex]).toLocaleString() : 'NA'
          } `}
        </Typography>
        {expanded && (
          <>
            <MetricTable title="listed" rows={listData} />
            <MetricTable title="delisted" rows={delistedData} />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default RentMetricCard;
