import { initialFilter } from '../contexts/Filter';
import { IFilter } from '../types/filter.type';

export const urlJoin = (...parts: string[]): string => {
  return parts
    .map((part) => part.replace(/^\/+|\/+$/g, ''))
    .filter((part) => part.length)
    .join('/');
};

export const getCurrentTimestamp = (): string => {
  const now = new Date();
  return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(
    2,
    '0'
  )}_${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(
    now.getSeconds()
  ).padStart(2, '0')}`;
};

export const removePrefixFromKeys = (obj: Record<string, any>, prefix: string): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key.startsWith(prefix) ? key.slice(prefix.length) : key, value])
  );
};

export const stringifyFilter = (filter: IFilter): string => {
  let params = [];
  params.push(`area[min]=${filter.min_area}`);
  params.push(`area[max]=${filter.max_area}`);
  params.push(`area[include_null]=${filter.include_null_area}`);

  params.push(`price[min]=${filter.min_price}`);
  params.push(`price[max]=${filter.max_price}`);
  params.push(`price[include_null]=${filter.include_null_price}`);

  params.push(`psf[min]=${filter.min_psf}`);
  params.push(`psf[max]=${filter.max_psf}`);
  params.push(`psf[include_null]=${filter.include_null_psf}`);

  if (filter.min_percent_leased !== initialFilter.min_percent_leased)
    params.push(`percent_leased[min]=${filter.min_percent_leased}`);
  if (filter.max_percent_leased !== initialFilter.max_percent_leased)
    params.push(`percent_leased[max]=${filter.max_percent_leased}`);
  if (filter.include_null_percent_leased !== initialFilter.include_null_percent_leased)
    params.push(`percent_leased[include_null]=${filter.include_null_percent_leased}`);

  if (filter.listedGrade.length > 0) {
    for (const grade of filter.listedGrade) {
      params.push(`listedGrade[]=${grade}`);
    }
  }
  if (filter.listed.length > 0) {
    for (const listed of filter.listed) {
      params.push(`listed[]=${listed}`);
    }
  }

  if (filter.article4.length > 0) {
    for (const article4 of filter.article4) {
      params.push(`article4[]=${article4}`);
    }
  }

  if (filter.tenure.length > 0) {
    for (const tenure of filter.tenure) {
      params.push(`tenure[]=${tenure}`);
    }
  }

  if (filter.propertyTypes.length > 0) {
    for (const propertyType of filter.propertyTypes) {
      params.push(`propertyTypes[]=${propertyType}`);
    }
  }

  if (filter.tags.length > 0) {
    for (const tag of filter.tags) {
      params.push(`tags[]=${tag}`);
    }
  }

  const validSaveOptions = new Set(['yes', 'no']);
  const saved = filter.saved.filter((save) => validSaveOptions.has(save));
  if (saved.length > 0 && saved.length < validSaveOptions.size) {
    if (saved.includes('yes')) {
      params.push('saved=true');
    }
    if (saved.includes('no')) {
      params.push('saved=false');
    }
  }

  if (filter.last_seen !== null) {
    params.push(`last_seen=${filter.last_seen}`);
  }

  if (filter.viewport) {
    params.push(`viewport[swLon]=${filter.viewport.sw.lng}`);
    params.push(`viewport[swLat]=${filter.viewport.sw.lat}`);
    params.push(`viewport[neLon]=${filter.viewport.ne.lng}`);
    params.push(`viewport[neLat]=${filter.viewport.ne.lat}`);
  }

  return encodeURI(params.join('&'));
};

export function serializeComplexTypes(item: any) {
  const result: { [key: string]: any } = {};
  for (const [key, value] of Object.entries(item)) {
    if (Array.isArray(value) || (value !== null && typeof value === 'object')) {
      // Serialize arrays and objects to a JSON string
      result[key] = JSON.stringify(value);
    } else {
      // Keep other types as they are
      result[key] = value;
    }
  }
  return result;
}
