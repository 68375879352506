import { BASE_ENDPOINT } from './config';
import { urlJoin } from './utils';

export const getMsoaMetrics = async (msoaCodes: string[], beds: number): Promise<Record<string, any> | null> => {
  try {
    const response = await fetch(urlJoin(BASE_ENDPOINT, 'metrics', 'msoa'), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ msoaCodes, beds }),
    });
    return await response.json();
  } catch (error) {
    console.error(`Error fetching msoa metrics for ${beds} beds ${msoaCodes}`, error);
    return null;
  }
};

export const getMsoaPsfDist = async (msoaCodes: string[], beds: number): Promise<Record<string, any> | null> => {
  try {
    const response = await fetch(urlJoin(BASE_ENDPOINT, 'metrics', 'psf-distribution'), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ msoaCodes, beds }),
    });
    return await response.json();
  } catch (error) {
    console.error(`Error fetching psf distribution for ${beds} beds ${msoaCodes}`, error);
    return null;
  }
};

export const getMsoaData = async (msoaCodes: string[], beds: number) => {
  try {
    const response = await fetch(urlJoin(BASE_ENDPOINT, 'metrics', 'download/msoa'), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ msoaCodes, beds }),
    });
    return await response.json();
  } catch (error) {
    console.error(`Error fetching msoa data for ${beds} beds ${msoaCodes}`, error);
    return null;
  }
};
